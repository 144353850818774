import { ClassValue, clsx } from "clsx";
import dayjs, { Dayjs } from "dayjs";
import { customAlphabet } from "nanoid";
import { twMerge } from "tailwind-merge";

export interface ObjectLiteral {
  [key: string]: any;
}

export function clamp(number: number, { min, max }: { min: number; max: number }): number {
  return Math.max(min, Math.min(number, max));
}

export function classNames(...classes: (string | undefined | null | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

// Don't use this for now as twMerge doesn't understand our tailwind.config.js
// See https://twitter.com/shadcn/status/1614692419039105024
// and https://github.com/dcastil/tailwind-merge/blob/v1.9.1/docs/features.md
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export enum ObjectTypes {
  Message = "Message",
  Datum = "Datum",
  Placeholder = "Placeholder",
}

// Custom alphabet for nanoids, excludes unaesthetic _ and -.
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", 12);

/**
 * Generate an ID for an object on the frontend.
 *
 * Currently these IDs are solely for the the client to keep track of objects,
 * and the backend is the canonical generator of IDs used in the database.
 *
 * The prefix is just for slightly nicer DX.
 *
 * @param objectType
 * @returns
 */
export function generateId(
  objectType: "message" | "datum" | "call" | "editor_session" | "placeholder" = "placeholder",
) {
  const id = nanoid();

  switch (objectType) {
    case "datum":
      return `datum_${id}`;
    case "message":
      return `msg_${id}`;
    case "editor_session":
      return `editor_${id}`;
    case "call":
      // Tool call
      return `call_${id}`;
    case "placeholder":
    default:
      return id;
  }
}

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper?: (children: React.ReactNode) => React.ReactNode;
  children: React.ReactNode;
}

export const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) =>
  condition && wrapper ? wrapper(children) : children;

export function pluralise(number?: number) {
  return number === 1 ? "" : "s";
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Set a maximum timeout for a promise
 *
 * @param promise The promise to wrap
 * @param maxTime The maximum time in milliseconds to wait for promise to resolve
 * @returns The result of the promise, or a string if the promise timed out
 */
export const withTimeout = async <T>(promise: Promise<T>, maxTime: number): Promise<T | string> => {
  const timeoutPromise = new Promise<string>((resolve) =>
    setTimeout(() => resolve(`The task took longer than ${maxTime}ms`), maxTime),
  );

  return Promise.race([promise, timeoutPromise]);
};

/**
 * Format a number as a percentage.
 *
 * @param number The number to format.
 * @param options The options to pass to the formatter.
 *    minimumFractionDigits defaults to 0.
 *    maximumFractionDigits defaults to 1.
 *    maximumSignificantDigits defaults to undefined.
 *    notation defaults to "compact".
 * @returns The formatted percentage.
 */
export const formatNumberPercentage = (
  number: number,
  {
    minimumFractionDigits = 1,
    maximumFractionDigits = 1,
    maximumSignificantDigits = undefined,
  }: { minimumFractionDigits?: number; maximumFractionDigits?: number; maximumSignificantDigits?: number } = {},
): string => {
  // This just means we don't put '∞%'... we just put '∞'
  if (number === Infinity) {
    return "∞";
  }

  return Number(number).toLocaleString(undefined, {
    style: "percent",
    maximumSignificantDigits,
    minimumFractionDigits,
    maximumFractionDigits,
  });
};

/**
 * Format a number to a precision of 4 decimal places.
 *
 * @param number The number to format.
 * @param options The options to pass to the formatter.
 *    minimumFractionDigits defaults to 0.
 *    maximumFractionDigits defaults to 4.
 * @returns The formatted number.
 */
export const formatNumberPrecise = (
  number: number,
  {
    minimumFractionDigits,
    maximumFractionDigits,
  }: { minimumFractionDigits?: number; maximumFractionDigits?: number } = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  },
): string => {
  return Number(number).toLocaleString(undefined, {
    style: "decimal",
    minimumFractionDigits,
    maximumFractionDigits,
  });
};

/**
 * Format a number to no decimal places.
 *
 * @param number The number to format.
 * @returns The formatted number.
 */
export const formatNumberRounded = (number: number): string => {
  return `${Number(number.toFixed(0))}`;
};

const SI_SYMBOLS = ["", "K", "M", "G", "T", "P", "E"];

/**
 * Format a number to a SI unit.
 *
 * @param number The number to format.
 * @returns The formatted number.
 */
export const formatNumberSI = (number: number): string => {
  if (number === 0) {
    return `${number}`;
  }

  const tier = Math.trunc(Math.log10(Math.abs(number)) / 3);
  if (tier === 0) return `${number}`;

  const suffix = SI_SYMBOLS[tier];
  const scaled = number / Math.pow(10, tier * 3);

  return scaled.toFixed(1) + suffix;
};

export const downloadFile = (data: BlobPart, fileName: string, type: string = "application/pdf"): void => {
  // Hack required to keep filename.
  // Otherwise this would have been preferable:
  //   const file = new File([data], fileName, { type: "application/pdf" });
  //   const fileURL = URL.createObjectURL(file);
  //   window.open(fileURL);
  // See https://stackoverflow.com/a/60378502
  const a = document.createElement("a");
  a.href = URL.createObjectURL(new Blob([data], { type }));
  a.setAttribute("download", fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getTimestampForFileName = (): string => {
  return dayjs().format("YYYY-MM-DD-HH-mm-ss");
};

// TODO - Dedup this with compareDates if reasonable
export const dateComparator = (a: Dayjs | undefined | null, b: Dayjs | undefined | null) => {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  return a.valueOf() - b.valueOf();
};
