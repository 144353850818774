import { useEffect } from "react";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/en";
import "dayjs/locale/en-gb";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

// The default locale is us-en ("en").
const GLOBAL_LOCALE = "en";

// List of supported locales. They're not all iso format. See https://github.com/iamkun/dayjs/tree/dev/src/locale
const SUPPORTED_LOCALES = ["en", "en-gb"] as const;

// We use the same relativeTime strings for all locales.
const customRelativeTime = {
  future: "in %s",
  past: "%s ago",
  s: "seconds",
  m: "a min",
  mm: "%d mins",
  h: "an hour",
  hh: "%d hours",
  d: "a day",
  dd: "%d days",
  M: "a month",
  MM: "%d months",
  y: "a year",
  yy: "%d years",
};

const DayjsConfig = () => {
  useEffect(() => {
    // Grab the user's locale from the browser.
    const userLocale =
      SUPPORTED_LOCALES.find((locale) => locale === navigator.language) ||
      SUPPORTED_LOCALES.find((locale) => locale === navigator.language.split("-")[0]) ||
      GLOBAL_LOCALE;

    dayjs.locale(userLocale);
    dayjs.updateLocale(userLocale, { relativeTime: customRelativeTime });
    console.log("Loaded and updated locale", userLocale);
  }, []);

  return <></>;
};

export default DayjsConfig;
