import { ConditionalWrapper } from "lib/utils";
import Link from "next/link";
import React, { useRef } from "react";
import { classNames } from "./utils/classNames";
import { UrlObject } from "url";
import { Icon } from "@phosphor-icons/react";
import KeyboardShortcut from "./KeyboardShortcut";

// TODO: remove 36
export type ButtonSize = 20 | 24 | 28 | 32 | 36 | 40 | 52;

export interface UnstyledButtonBaseProps extends React.HTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  href?: string | UrlObject;
  target?: string;
  rel?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  /** Disallow focus after click or through tab indexing. */
  disableFocus?: boolean;
}

type ButtonOrAnchorRefType = React.ForwardedRef<HTMLButtonElement | HTMLAnchorElement>;

export interface ButtonProps extends UnstyledButtonBaseProps {
  size?: ButtonSize;
  styling?: "solid" | "outline" | "ghost" | "link";
  /** Elevated works for outline only */
  elevated?: boolean;
  shade?: "blue" | "gray" | "red" | "yellow" | "green" | "black";
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
  tabIndex?: number;

  IconLeft?:
    | React.FunctionComponent<React.ComponentProps<"svg">>
    | React.FunctionComponent<React.ComponentProps<"img">>
    | Icon;
  IconRight?:
    | React.FunctionComponent<React.ComponentProps<"svg">>
    | React.FunctionComponent<React.ComponentProps<"img">>
    | Icon;

  className?: string;

  // TODO: Questionable prop. Using className feels more appropriate.
  block?: boolean;

  // Keyboard just displays the keyboard shortcuts, doesn't make it interactive.
  keyboard?: (string | React.ReactNode)[];

  children?: React.ReactNode;
}

/** Unstyled button/Link component underlying Button, IconButton and others */
export const UnstyledButtonBase = React.forwardRef(function ButtonComponent(
  { type = "button", disabled, href, disableFocus, onClick: onClickProp, ...props }: ButtonProps,
  ref: ButtonOrAnchorRefType,
) {
  // For some UI buttons we don't want to want to allow focus
  // after a mouse click (which is the default behaviour). To do this we
  // use mouseDown events and preventDefault on the event.
  let onClick: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
  let onMouseDown: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
  if (disableFocus) {
    onClick = (event) => {
      event.preventDefault();
    };
    onMouseDown = (event) => {
      event.preventDefault();
      if (onClickProp) {
        onClickProp(event);
      }
    };
  } else {
    onClick = onClickProp;
    onMouseDown = undefined;
  }

  const Element = href ? "a" : "button";

  return (
    // Use Next's Link component when acting as a link to enable Client-side transitions.
    <ConditionalWrapper
      condition={!!href && !disabled}
      wrapper={(children) => {
        return (
          <Link href={href as string | UrlObject} passHref legacyBehavior>
            {children}
          </Link>
        );
      }}
    >
      <Element
        // Spread props so this works with RadixUI's asChild.
        {...props}
        ref={ref as any}
        type={type}
        onClick={onClick}
        onMouseDown={onMouseDown}
        tabIndex={disabled || disableFocus ? -1 : 0}
        disabled={disabled}
        {...(href
          ? {
              target: props.target,
              rel: props.rel,
            }
          : {})}
      >
        {props.children}
      </Element>
    </ConditionalWrapper>
  );
});

const Button = React.forwardRef(function ButtonComponent(
  props: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement | HTMLAnchorElement>,
) {
  const {
    styling = "outline",
    elevated,
    size = 32,
    shade = "gray",
    loading,
    active,
    IconLeft,
    IconRight,
    className,
    ...rest
  } = props;

  if (elevated && styling !== "outline") {
    throw new Error("Elevated buttons only work with outline styling");
  }

  // TODO: use this https://cva.style/docs/getting-started instead to get typed, easy variants
  const classes = {
    base: "inline-flex whitespace-no-wrap truncate shrink-0 justify-center items-center relative select-none group font-medium",
    content: {
      20: "text-12-14 gap-6",
      24: "text-12-14 gap-6",
      28: "text-12-14 gap-6",
      32: "text-12-14 gap-8",
      36: "text-12-14 gap-8",
      40: "text-12-14 gap-8",
      52: "text-18-28 gap-16",
    },
    // Applied to all but 'link' buttons
    // The awkward change in padding is to account for the border in outline
    //  buttons so that '40' remains 40 px tall and the width doesn't shift

    padding: {
      20: classNames("rounded-[4px] h-20", styling === "outline" ? "px-[5px]" : "px-6"),
      24: classNames("rounded-[4px] h-24", styling === "outline" ? "px-[7px]" : "px-8"),
      28: classNames("rounded-[4px] h-28", styling === "outline" ? "px-[9px]" : "px-10"),
      32: classNames("rounded h-32", styling === "outline" ? "px-[11px]" : "px-12"),
      36: classNames("rounded h-36", styling === "outline" ? "px-[11px]" : "px-12"),
      40: classNames("rounded h-40", styling === "outline" ? "px-[15px]" : "px-16"),
      52: classNames("rounded h-52 ", styling === "outline" ? "px-[19px]" : "px-20"),
    },
    icon: {
      20: "w-12 h-12",
      24: "w-12 h-12",
      28: "w-14 h-14",
      32: "w-14 h-14",
      36: "w-16 h-16 -my-2",
      40: "w-16 h-16",
      52: "w-20 h-20",
    },
    block: "w-full",
    solid: {
      base: "focus:outline-none focus:ring focus:ring-oldblue-300 ", // applied to all
      normal: classNames(
        (shade === "blue" || shade === "gray") &&
          "bg-oldblue-600 text-white hover:bg-oldblue-700 active:bg-oldblue-700",
        shade === "red" && "bg-oldred-700 text-white hover:bg-oldred-600 active:bg-oldred-600",
        shade === "black" &&
          "bg-gray-900 text-white hover:bg-gray-800 active:bg-gray-700 hover:!text-white active:!text-white",
      ), // applied if not disabled or active
      disabled: "text-white bg-oldgray-400",
      active: "bg-oldblue-700 text-white shadow-inner",
    },
    outline: {
      base: "border",
      normal: classNames(
        shade === "blue" &&
          "border-oldblue-400 text-oldblue-600 hover:text-oldblue-700 active:bg-oldblue-200 active:text-oldblue-600",
        shade === "gray" &&
          "border-oldgray-400 text-oldgray-700 hover:border-oldgray-500 hover:text-oldgray-800 active:bg-oldgray-200 active:text-oldgray-700",
        // These are less common shades and less thought has been put into the colors
        shade === "green" &&
          "border-oldgreen-300 text-oldgreen-700 hover:text-oldgreen-700 active:bg-oldgreen-200 active:text-oldgreen-600",
        shade === "yellow" &&
          "border-oldyellow-300 text-oldyellow-700 hover:text-oldyellow-800 active:bg-oldyellow-200 active:text-oldyellow-00",
        shade === "red" &&
          "border-oldred-300 text-oldred-600 hover:text-oldred-700 active:bg-oldred-200 active:text-oldred-600",
      ),
      disabled: "border-oldgray-300 text-oldgray-400",
      active: classNames(
        shade === "blue" && "bg-oldblue-200 text-oldblue-600",
        shade === "gray" && "bg-oldgray-200 text-oldgray-700",
        shade === "green" && "bg-oldgreen-200 border-oldgreen-700 text-oldgreen-900",
        shade === "yellow" && "bg-oldyellow-200 text-oldyellow-700",
        shade === "red" && "bg-oldred-200 border-oldred-400 text-oldred-800",
      ),
    },
    ghost: {
      base: "focus:outline-none focus:ring focus:ring-oldblue-300 ",
      normal: classNames(
        "border border-transparent",
        shade === "blue" && "text-oldblue-600 hover:text-oldblue-700 active:bg-oldblue-200 active:text-oldblue-600",
        shade === "gray" && "text-oldgray-700 hover:text-oldgray-800 active:bg-oldgray-200 active:text-oldgray-700",
        shade === "green" &&
          "text-oldgreen-800 hover:text-oldgreen-700 active:bg-oldgreen-200 active:text-oldgreen-600",
        shade === "red" && "text-oldred-800 hover:text-oldred-700 active:bg-oldred-200 active:text-oldred-600",
      ),

      disabled: "text-oldgray-400",
      // Same as outline
      active: shade === "blue" ? "bg-oldblue-200 text-oldblue-600" : "text-text-base-1 border border-stroke-base-5",
    },
    link: {
      // TODO:!!! this is just copied from the 'ghost' atm
      base: "border-none focus:outline-none underline focus-visible:ring focus-visible:ring-blue  ",
      normal: "text-oldgray-700 hover:text-black",
      disabled: "text-oldgray-300",
      active: "",
    },
    cursor: loading ? "cursor-progress" : props.disabled ? "cursor-not-allowed" : "cursor-pointer",
    elevated: {
      base: "border-none bg-white",
      normal: "shadow-button-1 hover:shadow-button-2 active:shadow-button-2",
      disabled: "",
      active: "shadow-button-2",
    },
  };

  return (
    <UnstyledButtonBase
      // Spread props so this works with RadixUI's asChild.
      {...rest}
      ref={ref as any}
      className={classNames(
        className,
        classes.base,
        classes.content[size],
        classes.padding[size],
        classes[styling].base,
        props.block && classes.block,
        props.disabled ? classes[styling].disabled : active ? classes[styling].active : classes[styling].normal,
        classes.cursor,
        props.elevated && classes.elevated.base,
        props.elevated &&
          (props.disabled ? classes.elevated.disabled : active ? classes.elevated.active : classes.elevated.normal),
      )}
    >
      {IconLeft && (
        <IconLeft className={classNames(classes.icon[size], "shrink-0", shade === "black" && "text-white")} />
      )}
      {props.children}
      {/* TODO: Heads up that the shortcut isn't fully designed/finished and neither is how they should be used with all the buttons */}
      {props.keyboard?.length &&
        props.keyboard?.length > 0 &&
        props.keyboard?.map((key, i) => (
          <KeyboardShortcut
            key={i}
            shade={"blue"}
            className={classNames(i !== 0 && "-ml-6 ", "-my-4")}
            disabled={props.disabled}
          >
            {key}
          </KeyboardShortcut>
        ))}
      {IconRight && (
        <IconRight className={classNames(classes.icon[size], "shrink-0", shade === "black" && "text-white")} />
      )}
    </UnstyledButtonBase>
  );
});

export default Button;
