// Analytics

// Logic and constants that should be shared between the client and server analytics libraries.

// Avoid using Link Clicked - legacy. Use Button Clicked instead.
export type AnalyticsEvent = "Button Clicked" | "Link Clicked" | "AI Feature Called";

// Define allowed locations as string literals, use hyphens for spaces
type Location =
  | "playground"
  | "playground-history"
  | "log-export"
  | "organization-creation-form"
  | "organization-billing-plan-comparison"
  | "upsell-banner"
  | "debug"
  | "onboarding"
  | "evaluation-stats";

// Specific properties for each event can extend a base interface if they share common properties
// Try to use single words for properties but otherwise use underscores snake_case.
interface EventPropertiesBase {
  location: Location;
  purpose?: string; // Keep this hyphenated and short e.g. "sales-call"
  url?: string; // Not sure why this is needed but we have it on some events, would have though autocaptured.
  [key: string]: any; // Allow any other properties
}

export interface EventPropertiesMapping {
  "Button Clicked": EventPropertiesBase; // Add specific properties if needed
  "Link Clicked": EventPropertiesBase; // Customize as necessary
  "AI Feature Called": EventPropertiesBase;
}

// Constants to follow our object-action-properties naming convention
// https://humanloop.notion.site/Analytics-Naming-Convention-c3409da767c64a4497d6eb213e3a446e

export const SEGMENT_KEY = process.env.NEXT_PUBLIC_SEGMENT_KEY;
export const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;
export const LOGGING = false;

// Apply the cohere block based on the privacy level
export type PrivacyLevel = "high" | "moderate" | "low";
export const PRIVACY_CLASSNAMES: Record<PrivacyLevel, string> = {
  high: "cohere-block",
  moderate: "cohere-block",
  low: "",
};
