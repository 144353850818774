import { Metadata } from "next";
import Head from "next/head";
import { useRouter } from "next/router";

// Base metadata for the site.
const METADATA = {
  title: {
    template: "%s | Humanloop",
    default: "Humanloop",
  },
  description:
    "Humanloop is an enterprise-grade prompt management platform with best-in-class AI evaluation tools and LLM observability.",
  robots: {
    index: true,
    follow: true,
  },
  twitter: {
    card: "summary_large_image",
  },
  alternates: {
    canonical: "https://humanloop.com",
  },
  openGraph: {
    images: ["https://humanloop.com/assets/marketing/social/social-gradient.png"],
  },
} as const satisfies Metadata;

interface CustomHeadProps
  extends Pick<
    Metadata,
    "title" | "description" | "openGraph" | "manifest" | "themeColor" | "robots" | "alternates" | "twitter"
  > {
  image?: string;
}

export const TITLE_SEPARATOR = "›";
/** Customize the <head> tag for the page.
 *
 * Note: this uses a subset Metadata type from Next.js 14, and it is manually inserted
 * into the page because we're still on page router.
 * So if you're specifying an esoteric property, you'll need to manually add it below.
 */
function CustomHead(props: CustomHeadProps): JSX.Element {
  // Title logic, see https://nextjs.org/docs/app/api-reference/functions/generate-metadata#template-object
  let title: string;
  // If string provided, apply template
  if (typeof props.title === "string") {
    if (props.title) {
      title = METADATA.title.template.replace("%s", props.title);
    } else {
      // If the title is empty string, use the default title
      title = METADATA.title.default;
    }
  } else {
    // If object provided, use absolute or the resolved default/template
    if (props.title && "absolute" in props.title) {
      title = props.title.absolute;
    } else {
      const titleObj = { ...METADATA.title, ...props.title };
      title = titleObj.template.replace("%s", titleObj.default);
    }
  }

  const description = props.description || METADATA.description;

  const image = props.image || METADATA.openGraph.images[0];
  const { asPath } = useRouter();
  return (
    <>
      <Head>
        {/* Top one is preferred as its an SVG that responds to system dark mode settings */}
        <link rel="icon" href="/favicon/favicon.svg" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />

        <link rel="manifest" href="/favicon/site.webmanifest" />
        <title>{title}</title>
        <meta name="og:title" content={title} />

        <meta name="title" content={title} />
        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />

        <link rel="canonical" href={`https://humanloop.com${asPath}`} />

        <meta property="og:locale" content="en_US" />

        {/* Add structured data for better rich snippets */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Humanloop",
            "url": "https://humanloop.com",
            "logo": "https://humanloop.com/img/logos/SVG/symbol.svg",
            "description": description,
          })}
        </script>

        {/* Add viewport meta tag if not already present in _app.tsx */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Add robots meta tag */}
        <meta name="robots" content="index, follow" />
      </Head>
    </>
  );
}

export default CustomHead;
