// Requests that support authentication.
// These are all unversioned requests used for hl-alpha, and mostly return the old-style user with integer ID.

import { AxiosResponse } from "axios";
import { ApiService } from "./api.service";
import { clearActiveOrganizationSessionStorage } from "@/context/ActiveOrganizationProvider";
import { analytics } from "@/lib/analytics/analytics.client";
import { hlToast, ToastVariant } from "@components/library/Toast";

export interface LoginForAccessTokenResponse {
  access_token: string;
  token_type: "bearer";
  // user: UnversionedUserResponse; // Avoid using instead.
}

export const loginForAccessToken = (
  username: string,
  password: string,
): Promise<AxiosResponse<LoginForAccessTokenResponse>> => {
  const params = new URLSearchParams();
  params.append("username", username);
  params.append("password", password);
  return ApiService.post("/token", params);
};

export const loginAsToken = (username: string): Promise<AxiosResponse<LoginForAccessTokenResponse>> => {
  return ApiService.post("/access", {
    username: username,
  });
};

export interface RecoverUserResponse {
  msg: string;
}

export const recoverUser = (email: string): Promise<AxiosResponse<RecoverUserResponse>> => {
  return ApiService.post(`/users/recover/${email}`, undefined);
};

interface AuthResponse {
  url: string;
}

export interface AuthCallbackResponse extends AuthResponse {
  access_token: string;
  new_user: boolean;
  first_name: string;
}

export type AuthProvider = "GoogleOAuth" | "GitHubOAuth" | "MicrosoftOAuth" | "SAML";

/** Called when you click on sign in with SSO Provider. */
export const authenticateUser = (
  provider: AuthProvider,
  email: string | null = null,
): Promise<AxiosResponse<AuthResponse>> => {
  // We do have process.env.NEXT_PUBLIC_BASE_URL set up on vercel and locally,
  // BUT we can't easily get the nice preview URLs that vercel puts on PRs
  // (the ones that feature the branch name), so we use window.location.origin
  // so that we don't get redirected from nice-pr-preview-url.vercel.app to
  // arbitrary-ugly-hash.vercel.app (which also forces us to log in each time
  // we go that preview)
  const base_url = window ? window.location.origin : process.env.NEXT_PUBLIC_BASE_URL;
  let url = `/auth?&base_url=${base_url}&provider=${provider}`;
  if (email) {
    url += `&email=${email}`;
  }
  return ApiService.post(url);
};

/** Called on returning from SSO provider with a code */
export const authenticateUserCallback = (code: string): Promise<AxiosResponse<AuthCallbackResponse>> => {
  const base_url = window ? window.location.origin : process.env.NEXT_PUBLIC_BASE_URL;
  return ApiService.post(`/auth/callback?code=${code}&base_url=${base_url}`);
};

export const handleSSOLogin = async (provider: AuthProvider, email: string | null = null) => {
  const authResponse = await authenticateUser(provider, email);
  window.location.href = authResponse.data.url;
  // Clearing the active org on login
  clearActiveOrganizationSessionStorage();
  analytics.enableTracking();
};
