import { analytics } from "@/lib/analytics/analytics.client";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import posthog from "posthog-js";
import router from "next/router";
import { POSTHOG_KEY } from "@/lib/analytics/shared";

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

export function AnalyticsProvider({ children }: AnalyticsProviderProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const trackingDisabled = analytics.isTrackingDisabled();

  // Initialise PostHog
  useEffect(() => {
    if (trackingDisabled) {
      return;
    }
    if (POSTHOG_KEY) {
      posthog.init(POSTHOG_KEY, {
        // humanloop.com/ingest is a nextjs rewrite to app.posthog.com, acting
        // as a proxy so we don't lose data to ad blockers.
        api_host: "/ingest",
        opt_in_site_apps: true,
        person_profiles: "identified_only",
      });
      posthog.debug(false);
    } else {
      console.log("PostHog key not found. Not enabled.");
    }
  }, [trackingDisabled]);

  // Track page views
  useEffect(() => {
    if (trackingDisabled) {
      return;
    }
    // Track the initial page load
    analytics.page();

    // Track page views
    const handleRouteChange = () => analytics.page();

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [pathname, searchParams, trackingDisabled]);

  return <>{children}</>;
}
